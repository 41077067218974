import * as React from "react";
import Layout from "../../components/Layout";
import empty_png from "../../images/logo/empty.png";
import ImageScroller from "../../ui-blocks/ImageScroller";
import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";
import Checkerboard from "../../ui-blocks/Checkerboard";

import Messaging_Platform_Question_Format_CTA_Search_Field_svg from "../../images/8. Messaging Platform/Messaging_Platform_Question_Format_CTA_Search_Field.svg";
import Messaging_Platform_Question_Format_Digital_Button_svg from "../../images/8. Messaging Platform/Messaging_Platform_Question_Format_Digital_Button.svg";

import Messaging_Platform_Question_Format_Lozenge_svg from "../../images/8. Messaging Platform/Messaging_Platform_Question_Format_Lozenge.svg";
import Messaging_Platform_Question_Format_Lozenge_Center_svg from "../../images/8. Messaging Platform/Messaging_Platform_Question_Format_Lozenge_Center.svg";
import Messaging_Platform_Question_Format_Lozenge_Left_svg from "../../images/8. Messaging Platform/Messaging_Platform_Question_Format_Lozenge_Left.svg";

import Messaging_Platform_Question_Format_Example_1_svg from "../../images/8. Messaging Platform/Messaging_Platform_Question_Format_Example_1.svg";
import Messaging_Platform_Question_Format_Example_2_svg from "../../images/8. Messaging Platform/Messaging_Platform_Question_Format_Example_2.svg";

const MessagingPlatform = (props) => {
  return (
    <Layout>
      <div
        className="page-header image-header"
        id="overview"
        data-navindex="1"
        style={{ backgroundColor: "rgb(154, 159, 163)", height: "650px" }}
      >
        <div className="image" style={{ display: "flex", height: "650px" }}>
          <img
            alt=""
            src={{ empty_png }}
            style={{ height: "500px", width: "1140px", margin: "auto" }}
          />
        </div>

        {/* <div className="background" style={{backgroundColor:'rgb(154, 159, 163)'}}>
            <picture>
                <source media="(max-width:767px)" srcSet="/brandguidelines/imgix/tagline/section-header/tagline_header_mobile.jpg"/>
                <source media="(min-width:768px) and (max-width:1024px)"  srcSet="/brandguidelines/imgix/tagline/section-header/tagline_header_tablet.jpg"/>
                <source media="(min-width:1900px)" srcSet="/brandguidelines/imgix/tagline/section-header/tagline_header.jpg"/>
                    <img alt="" src="/brandguidelines/imgix/tagline/section-header/tagline_header_desktop.jpg" alt="Campers near an open Toyota vehicle in nature setting"/>
            </picture>
        </div> */}
      </div>

      <section className="component Section">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Messaging Platform</h2>
          </div>
        </div>
        {/* <div className='blog-content'></div> */}
        {/* <div className='image'></div> */}
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              The following provides guidelines for the <em>Let's Go Places</em>
              messaging platform (often referred to as 'Hey, Toyota'). This
              platform covers all product, services and offer-based
              communications from Toyota New Zealand to consumers that fall
              outside the scope of bespoke campaigns.
            </div>

            <div className="copy spaced-p">
              The creative construct does not apply to individual store
              marketing where the voice, or person speaking, is not that of
              Toyota New Zealand.
            </div>

            <div className="copy spaced-p">
              'Hey, Toyota' represents more than an advertising platform. It's a
              connection idea. An approach that will help Toyota engage with
              customers at every stage of their journey.
            </div>

            <div className="copy spaced-p">
              It's all about our brand being unexpectedly responsive.
            </div>

            <div className="copy">
              And it's how Toyota New Zealand will stand out from the crowd and
              set a new standard for people-centric comms, and experiences
              people actually want to engage with.
            </div>
          </div>
        </div>
      </section>

      <section className="component Section" id="approach-construct">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">The Approach (Construct)</h2>
          </div>
        </div>
        {/* <div className='blog-content'></div>
        <div className='image'></div> */}
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              The messaging platform construct is founded on a
              question-and-response style interaction between characgers in real
              world and Toyota New Zealand.
            </div>

            <div className="copy spaced-p">
              In TV this comes to life as a high energy conversation, jumpling
              between the real world where our customers are abd a white studio
              environment where vehicles and graphics are based.
            </div>

            <div className="copy spaced-p">
              This format is reflected in other channels as well. In print and
              digital executions, headlines can take the form of a question and
              answer, or just the answer (if it infers a question has been
              asked).
            </div>

            <div className="copy spaced-p">
              It's all about our brand being unexpectedly responsive.
            </div>

            <div className="copy spaced-p">
              And it's how Toyota New Zealand will stand out from the crowd and
              set a new standard for people-centric comms, and experiences
              people actually want to engage with.
            </div>
          </div>
        </div>

        {/* single image */}
        <div
          className="image"
          style={{ background: "#fafafa", paddingBottom: "40px" }}
          data-compid="used-as-headline-1"
        >
          <div className="full-width">
            <div className="image" style={{ display: "flex", height: "500px" }}>
              <img
                alt=""
                src={{ empty_png }}
                style={{
                  height: "500px",
                  width: "1140px",
                  margin: "0 auto 0 auto",
                }}
              />
            </div>
          </div>
        </div>
        {/* end single image */}
      </section>

      <section className="component Section" id="hey-toyota-photography">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Hey Toyota Photography</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy sp">
              There are two specific photography components in the Messaging
              Platform - people and the product.
            </div>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <h3 className="title" style={{ color: "#000" }}>
              People
            </h3>
            <div className="copy spaced-p">
              Talent will be shot on locatoin, as if we found them where they
              are. Natural settings, like a High street, in front of a school,
              in a garden, standing on a remote beach, in a field, etc.
            </div>
            <div className="copy spaced-p">
              We need to capture people looking straight down the barrel of the
              camera, and always looking like they're mid-question - with a
              questioning expression, mouth open as if they're speaking, or head
              cocked.
            </div>
            <div className="copy spaced-p">
              It is important that these feel like active engagements with
              Toyota, rather than smiling portraits.
            </div>
          </div>
        </div>

        {/* image block4x4 */}
        <div style={{ background: "white", paddingBottom: "80px" }}>
          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end image block4x4 */}

        <div
          className="blog-content darker-background"
          style={{ paddingTop: "80px", paddingBottom: "80px" }}
        >
          <div className="container onecolumn">
            <h3 className="title" style={{ color: "#000" }}>
              Product
            </h3>
            <div className="copy">
              This ims imagery of our vehicles set withing a white studio, where
              we can control the lighting and shoot the car at flattering
              angles. Cars can be showcaasaed on their own, with props (if
              necessary) or as part of a range.
            </div>
          </div>
        </div>

        <div style={{ background: "white", paddingBottom: "80px" }}>
          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="component Section" id="hey-toyota-copy">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Hey Toyota Copy</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy">
              How we speak is friendly, conversational and energetic.
            </div>
            <br />
            <div className="copy">
              We don't get caught up in jargon or corportae speak - we explain
              it.
            </div>
            <br />
            <div className="copy">Succinctly.</div>
            <br />
            <div className="copy">
              This is about putting our responsiveness and customer-centric
              approach on display.
            </div>

            <br />
            <div className="copy">
              *NOTE* Include authentic, honest customers point of tone.
            </div>
          </div>
        </div>
      </section>

      <section className="component Section" id="question-formats">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Question Formats</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              Under the messaging platform, headlines are broken into two
              separate elements. The question (from the consumer) and the
              answer.
            </div>

            <div className="copy spaced-p">
              Questions sit within a black text bubble, that is fitted to the
              type and can either appear over imagery of talent (when used) or
              alongside the answer.
            </div>
            <div className="copy spaced-p">
              Responses appear in the white studio space along with the vehicle
              and do not have any outline.
            </div>

            <div className="copy spaced-p">
              There are three ways to do this:
            </div>
            <div className="copy spaced-p">
              *NOTE*-Unpack options for 1. Question + Answer 2. Question is
              implied in Answer
            </div>
          </div>
        </div>

        <ImageScroller
          style={{ backgroundColor: "white", paddingBottom: "40px" }}
          images={[
            {
              image: {
                svg: Messaging_Platform_Question_Format_Example_1_svg,
                style: { height: "300px", width: "800px" },
              },
            },
            {
              image: {
                svg: Messaging_Platform_Question_Format_Example_2_svg,
                style: { height: "300px", width: "800px" },
              },
            },
          ]}
        ></ImageScroller>

        <div
          className="blog-content darker-background"
          style={{ paddingTop: "80px" }}
        >
          <div className="container onecolumn">
            <h3 className="title" style={{ color: "#000" }}>
              Question formats - lozenge
            </h3>
            <div className="copy">
              The question portion of a headline sits within a text bubble, or
              lozenge device. The height and width of the lozenge is relative to
              the size of the text and can be calculated by the text cap height.
            </div>
            <br />
            <div className="copy">
              The total lozenge height is 1X from the text cap height and 1X
              from the text baseline. The total width of the lozenge is 2X from
              the left and right edge of text.
            </div>

            <br />
            <div className="copy">
              This is then repeated to create the second text line as shown.
            </div>
          </div>
        </div>

        {/* begin T-image block */}
        <div style={{ background: "#fafafa", paddingBottom: "80px" }}>
          <div className="image">
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <img
                  alt=""
                  src={Messaging_Platform_Question_Format_Lozenge_svg}
                  style={{
                    height: "500px",
                    width: "1140px",
                    margin: "0 auto 0 auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={
                        Messaging_Platform_Question_Format_Lozenge_Center_svg
                      }
                      style={{ width: "570px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={Messaging_Platform_Question_Format_Lozenge_Left_svg}
                      style={{ width: "570px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end T-image block */}
      </section>

      <section className="component Section" id="callout-graphics">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">CTA/Call out graphics</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy">
              Below are some additional guidelines for brand campaigns that have
              their own lock up or end line.
            </div>
          </div>
        </div>

        {/* TODO: Checkersboard  */}

        <Checkerboard
          rows={[
            {
              // intentionally wrapping empty_png with curly braces - then browser displays no-image icon
              img_src: Messaging_Platform_Question_Format_CTA_Search_Field_svg,
              img_style: { width: "570px", height: "570px" },
              title: "CTA search field",
              copy: "Text is Toyota Type Regular. <br/> The magnifying glass is 140% of the text cap height. The space between the magnifying glass and the text is 1X. The search lozenge height is 1X from the text cap height and 1X from the text baseline. <br/>The total width of the lozenge is 2X from the left and right edge of text. <br/>Lozenge si 12% black as shown.",
            },
            {
              img_src: Messaging_Platform_Question_Format_Digital_Button_svg,
              img_style: { width: "570px", height: "570px" },
              title: "Digital button",
              copy: "The text is Toyota Type Semibold.<br/>The digital button lozenge height is 1X from the text cap height and 1X from the text baseline.<br/>The total width of the lozenge is 2X from the left and right edge of text as shown.<br/>Lozenge is clear with a black keyline as shown.",
            },
          ]}
        />

        <div className="section-header darker-background">
          <div className="container">
            <h3 className="title" style={{ fontSize: "24px" }}>
              Examples
            </h3>
          </div>
        </div>

        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
          ]}
        ></ImageScroller>
      </section>

      <section className="component Section" id="motion-graphics">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Motion Graphics</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              Below are some additional guidelines for brand campaigns that have
              their own lock up or end line.
            </div>
          </div>
        </div>

        {/* begin T-image block */}
        <div style={{ background: "#fafafa", paddingBottom: "80px" }}>
          <div className="image">
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <img
                  alt=""
                  src={{ empty_png }}
                  style={{
                    height: "500px",
                    width: "1140px",
                    margin: "0 auto 0 auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end T-image block */}
      </section>

      <section className="component Section" id="layout-examples">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Layout Examples</h2>
          </div>
        </div>

        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
          ]}
        ></ImageScroller>
      </section>

      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="TVC Endframe"
        previousUrl="/brandguidelines/tvc-endframe"
        nextText="Next"
        nextHeader="Brand Campaigns"
        nextUrl="/brandguidelines/brand-campaigns"
      />
    </Layout>
  );
};

export default MessagingPlatform;
