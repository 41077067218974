import React from "react";

const Checkerboard = (props) => {
  const style = props.style || {};
  style.backgroundColor = "#fafafa";

  return (
    <div className="checkerboard" style={style}>
      <div className="full-width">
        <ul>
          {props.rows.map((row) => {
            const imageStyle = row.img_style || {
              height: "400px",
              width: "400px",
            };
            imageStyle.margin = "auto";
            return (
              <li>
                {/* First row */}
                <div className="wrap" style={{ background: "transparent" }}>
                  <div
                    className="left"
                    style={{ margin: "0px", background: "transparent" }}
                  >
                    <div className="block">
                      <div>
                        <div
                          className="image"
                          style={{ display: "flex", height: "570px" }}
                        >
                          {/* <img alt="" src={{empty_png}} style={{height: '400px', width: '400px', margin: 'auto'}}/> */}
                          <img alt="" src={row.img_src} style={imageStyle} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="right"
                    style={{ margin: "0px", background: "white" }}
                  >
                    <div className="block">
                      <span></span>
                      <div className="copyWrap">
                        <h3 className="title">{row.title}</h3>
                        <p
                          className="text"
                          dangerouslySetInnerHTML={{ __html: row.copy }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end of first row  */}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Checkerboard;
